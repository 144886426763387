import WebServiceRequest from "../Api/WebServiceRequest";

class GetMainObjectLanguages extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("MainObjectLanguage/GetMainObjectsWithLanguages");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

export { GetMainObjectLanguages };
