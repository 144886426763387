<template>
  <b-overlay :show="isLoading">
    <b-modal
      id="modal-langs"
      size="lg"
      centered
      ok-variant="primary"
      :title="`افزودن زبان جدید به ${objectName}`"
      ok-title="بستن"
      ok-only
      @ok="closeLangsModal"
    >
      <b-container fluid>
        <b-form-row>
          <b-col
            cols="12"
            md="6"
            v-for="(item, index) in languageList"
            :key="index"
          >
            <div
              class="w-100 d-flex flex-column align-items-center justify-content-center"
            >
              <p class="">
                {{ item.language.persianName }}
              </p>
            </div>
          </b-col>
        </b-form-row>
      </b-container>
    </b-modal>
  </b-overlay>
</template>

<script>
export default {
  emits: ["closeModal"],
  props: {
    objectType: {
      // Blog = 1,
      // BlogCategory = 2,
      // Form = 3,
      // Menu = 4,
      // MenuItem = 5,
      // Page = 6
      type: [String, Number],
      required: true,
    },
    mainId: {
      type: String,
      required: true,
    },
    objectName: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.getAllObjectLanguages();
  },
  data() {
    return {
      isLoading: false,
      languageList: null,
    };
  },
  methods: {
    closeLangsModal() {
      this.$emit("closeModal");
    },
    async getAllObjectLanguages() {
      try {
        this.isLoading = true;
        let _this = this;
        let getMainObjectLanguages = new GetMainObjectLanguages(_this);
        getMainObjectLanguages.setRequestParam({
          type: _this.objectType,
          mainId: _this.mainId,
        });
        await getMainObjectLanguages.fetch((response) => {
          if (response.isSuccess) {
            _this.languageList = response.data;
            console.log(_this.languageList);
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BOverlay,
    BModal,
    BContainer,
    BFormRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
  },
};
import { GetMainObjectLanguages } from "@/libs/Api/MainObjectLanguage";
import {
  BOverlay,
  BModal,
  BContainer,
  BFormRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
</script>
